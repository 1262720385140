<template>
<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 1.5rem;">
    <div class="action-selection__content">
        <h1>{{ $t('ActionSelection.title') }}</h1>
        <p>
            {{ $t('ActionSelection.message') }}
        </p>

        <PtySelector :options="possibleActions" v-model="selectedAction" :value="selectedAction" />

    </div>
    <transition name="fade" mode="out-in">
        <img v-if="selectedAction == 1" src="../../assets/images/illustrations/ready-to-play.png" style="width: 70%" :key="1" />
        <img v-if="selectedAction == 2" src="../../assets/images/illustrations/booking.png" style="width: 70%" :key="2" />
    </transition>
</div>
</template>

<script>
import PtySelector from '../../components/PtySelector.vue';

import UserTickIcon from '../../assets/images/icons/user-tick.svg';
import TicketIcon from '../../assets/images/icons/ticket.svg';

export default {
    name: 'ActionSelection',
    components: {
        PtySelector
    },
    data: () => ({
        selectedAction: 1
    }),

    computed: {
        possibleActions() {
            return [
                { id: 1, title: this.$t('ActionSelection.options.start.title'), caption: this.$t('ActionSelection.options.start.caption'), icon: UserTickIcon },
                { id: 2, title: this.$t('ActionSelection.options.book.title'), caption: this.$t('ActionSelection.options.book.caption'), icon: TicketIcon }
            ];
        }
    },

    mounted() {
        this.$emit('input', this.selectedAction);
    },

    watch: {
        selectedAction() {
            this.$emit('input', this.selectedAction);
        }
    }
}
</script>


<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
  color: #000;
  font-family: "Sofia Pro";
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.0425rem;
  margin: 0;
}

p {
    color: #494949;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%; /* 1.88169rem */
}
</style>