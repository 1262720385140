<template>
<div class="on-boarding">
    <PtyBack @back="goBack" :disabled="disabledBack.includes(currentStep)" :search="selectedAction == 2 && currentStep === 2" @searchToggle="searchVisible = !searchVisible" :searchPrimary="searchVisible" />
    <transition name="slide" mode="out-in">
        <ActionSelection v-if="currentStep === 1" :key="1" v-model="selectedAction" />
        <template v-if="selectedAction == 2">
            <EventSelection v-if="currentStep === 2" :key="2" v-model="selectedEvent" :searchVisible="searchVisible" />
        </template>

        <template v-if="selectedAction == 1">
            <GenderSelection v-if="currentStep === 2" :key="2" v-model="partecipant.type" :value="partecipant.type" />
            <Nickname v-if="currentStep === 2.5" :key="2.5" v-model="checkInInfo" :value="checkInInfo" :in_participant="partecipant"/>
            <QRCode v-if="currentStep === 3" :key="3" v-model="checkInResult" :checkInInfo="checkInInfo" :participant="partecipant"/>

            <TableModeSelection v-if="currentStep === 4" :key="4" v-model="tableMode" />
            <TablePreference v-if="currentStep === 5" :key="5" v-model="preferred_participant_friendly_id" :myCode="partecipant.friendly_id" />
            <TableNumber v-if="currentStep === 6" :key="6" v-model="tableNumber" />

            <GenderPreferenceSelection v-if="currentStep === 7" :key="7" v-model="partecipant.looking_for_type" />

            <InteresetSelection v-if="currentStep === 8" :key="8" v-model="partecipant.ideal_match" />

            <SelfieSelection v-if="currentStep === 9" :key="9" v-model="selfieBlob" :partecipant="{
                nickname: partecipant.nickname || checkInInfo.nickname,
                age: partecipant.age || checkInInfo.age,
                age_of_coparticipant: partecipant.age_of_coparticipant || checkInInfo.age_of_coparticipant,
                city: partecipant.city || checkInInfo.city
            }" />

            <TagSelection v-if="currentStep === 10" :key="10" v-model="partecipant.tag" />
        </template>

    </transition>

    <PtyNext v-if="selectedAction == 1 && currentStep > 3" :steps="7" :current="currentStep - 3" @next="goNext" />
    <PtyNext v-else :steps="0" :current="currentStep" @next="goNext" />
</div>
</template>

<script>
// @ts-ignore
import * as Sentry from '@sentry/vue';
import {
    partecipantService
} from '@/rest';
import {
    fileService
} from '@/rest';
import axios from 'axios';
import ActionSelection from './Steps/ActionSelection.vue';
import EventSelection from './Steps/EventSelection.vue';
import QRCode from './Steps/QRCode.vue';
import GenderSelection from './Steps/GenderSelection.vue';
import TableModeSelection from './Steps/TableModeSelection.vue';
import TableNumber from './Steps/TableNumber.vue';
import TablePreference from './Steps/TablePreference.vue';
import GenderPreferenceSelection from './Steps/GenderPreferenceSelection.vue';
import InteresetSelection from './Steps/InterestSelection.vue';
import SelfieSelection from './Steps/SelfieSelection.vue';
import TagSelection from './Steps/TagSelection.vue';
import Nickname from './Steps/Nickname.vue';

import PtyBack from '../components/PtyBack.vue';
import PtyNext from '../components/PtyNext.vue';
export default {
    name: 'OnBoarding',
    components: {
        PtyBack,
        PtyNext,

        ActionSelection,
        EventSelection,
        Nickname,
        QRCode,
        GenderSelection,
        TableModeSelection,
        TableNumber,
        TablePreference,
        GenderPreferenceSelection,
        InteresetSelection,
        SelfieSelection,
        TagSelection
    },
    data: () => ({
        currentStep: 1,
        selectedAction: null,
        selectedEvent: null,

        tableMode: 'AUTO',
        tableNumber: null,

        selfieBlob: null,

        // event onboarding
        checkInResult: '',
        partecipant: {
            id: null,
            preferred_participant_friendly_id: null,
            looking_for_type: null,
            ideal_match: null,
            tag: null,
            type: null,
            picture_updated_at: null,
        },

        checkInInfo: {
            nickname: null,
            age: null,
            age_of_coparticipant: null,
            city: null,
        },

        searchVisible: false,

        preferred_participant_friendly_id: null,

        event: null,

        disabledBack: [
            1, // action selection
            4, // table mode selection, prevent going back to qrcode
            6, // table number, prevent going back to table mode
            7, // interest selection, prevent going back to table number
        ],

        saveParticipantTimeout: null
    }),

    methods: {
        goBack() {
            // if in qr code screen, go back to previous step
            if(this.currentStep == 3){
                this.currentStep = 2.5; // WORKAROUND TO AVOID STEP REMAPPING
                return;
            }

            // if current step is 2.5, go back to 2 (Nickname -> Gender)
            if(this.currentStep == 2.5){
                this.currentStep = 2; // WORKAROUND TO AVOID STEP REMAPPING
                return;
            }


            if (this.currentStep > 1) {
                // i want to skip table preference
                if (this.currentStep == 6 && this.tableMode == 'AUTO') {
                    this.currentStep = 4;
                    return;
                }
                this.currentStep--;
            } else {
                this.$router.push(('/'))
            }
        },

        async goNext() {
            var vm = this;
            if (this.selectedAction == 1) {
                if (this.currentStep == 2) {
                    if (this.partecipant.type == null) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.selectGender'),
                            position: 'top-center',
                            color: 'primary', duration: 5000,
                        });
                        return;
                    }

                    this.currentStep = 2.5; // WORKAROUND TO AVOID STEP REMAPPING
                    return;
                }

                if (this.currentStep == 2.5) { // WORKAROUND TO AVOID STEP REMAPPING

                    if (this.checkInInfo.nickname == null || this.checkInInfo.nickname.length == 0) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.insertNickname'),
                            position: 'top-center',
                            color: 'primary', duration: 5000,
                        });
                        return;
                    }

                    if (this.checkInInfo.age == null || isNaN(this.checkInInfo.age) || this.checkInInfo.age < 18) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.insertValidAge'),
                            position: 'top-center',
                            color: 'primary', duration: 5000,
                        });
                        return;
                    }

                    // age_of_coparticipant required if couple
                    if (this.partecipant.type == 'COUPLE' && (this.checkInInfo.age_of_coparticipant == null || isNaN(this.checkInInfo.age_of_coparticipant) || this.checkInInfo.age_of_coparticipant < 18)) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.insertValidAge'),
                            position: 'top-center',
                            color: 'primary', duration: 5000,
                        });
                        return;
                    }

                    if (this.checkInInfo.city == null || this.checkInInfo.city.length == 0) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.insertCity'),
                            position: 'top-center',
                            color: 'primary', duration: 5000,
                        });
                        return;
                    }
                    this.currentStep = 3; // WORKAROUND TO AVOID STEP REMAPPING
                    return;
                }

                if (this.currentStep == 3) {
                    if (this.partecipant.id == null || this.partecipant.id.length == 0) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.scanQRCode'),
                            position: 'top-center',
                            color: 'primary', duration: 5000,
                        });
                        return;
                    }

                }

                if (this.currentStep == 5) {

                    // we have to set the preferred_participant_friendly_id, then wait for match
                    if (this.preferred_participant_friendly_id == null || this.preferred_participant_friendly_id.length < 4) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.insertFriendCode'),
                            position: 'top-center',
                            color: 'primary', duration: 5000,
                        });
                        return;
                    }

                    const loading = this.$vs.loading({
                        text: 'Ricerca amico..'
                    });
                    try {


                        this.partecipant.preferred_participant_friendly_id = this.preferred_participant_friendly_id;

                        let friendFound = false;
                        let startedAt = Date.now();
                        while (!friendFound && Date.now() - startedAt < 15000) {
                            friendFound = await new Promise((resolve) => {

                                partecipantService.detail(vm.preferred_participant_friendly_id).then((partecipant) => {
                                    console.log(partecipant, vm.partecipant);

                                    console.log(partecipant.preferred_participant_friendly_id, vm.partecipant.friendly_id);
                                    if (partecipant && partecipant.id) {
                                        // we have the participant
                                        if (partecipant.preferred_participant_friendly_id == vm.partecipant.friendly_id) {
                                            console.log('RESOLVE')
                                            return resolve(true);
                                        }
                                    }

                                    return resolve(false);
                                }).catch(() => {
                                    // do nothing
                                    return resolve(false);
                                });

                            });

                            console.log(friendFound);

                            // wait 2 seconds
                            await new Promise((resolve2) => {
                                setTimeout(() => {
                                    resolve2();
                                }, 2000);
                            });
                        }

                        loading.close();

                        if (!friendFound) {
                            this.$vs.notification({
                                title: this.$t('Common.error'),
                                text: this.$t('OnBoarding.messages.friendNotFound'),
                                position: 'top-center',
                                color: 'primary', duration: 5000,
                            });

                            // revert
                            this.partecipant.preferred_participant_friendly_id = null;

                            return;
                        }

                    } catch (e) {
                        Sentry.captureException(e);
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.friendNotFound'),
                            position: 'top-center',
                            color: 'primary', duration: 5000,
                        });
                        this.partecipant.preferred_participant_friendly_id = null;
                        loading.close();
                        return;
                    }

                }

                if (this.currentStep == 6 && this.tableNumber == null) {
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('OnBoarding.messages.canNotAssignTable'),
                        position: 'top-center',
                        color: 'primary', duration: 5000,
                    });
                    return;
                }

                if (this.currentStep == 7 && (this.partecipant.looking_for_type == null || this.partecipant.looking_for_type.length == 0)) {
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('OnBoarding.messages.selectGenderPreference'),
                        position: 'top-center',
                        color: 'primary', duration: 5000,
                    });
                    return;
                }

                if (this.currentStep == 8 && (this.partecipant.ideal_match == null || this.partecipant.ideal_match.length == 0)) {
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('OnBoarding.messages.selectInterest'),
                        position: 'top-center',
                        color: 'primary', duration: 5000,
                    });
                    return;
                }

                if (this.currentStep == 9) {
                    if (this.selfieBlob == null) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.takeSelfie'),
                            position: 'top-center',
                            color: 'primary', duration: 5000,
                        });
                        return;
                    }

                    const loading = this.$vs.loading({
                        text: this.$t('OnBoarding.messages.uploadingImage')
                    });

                    try {

                        // upload image
                        const uploadUrl = await fileService.getSignedUploadURL({
                            scope: 'SELFIE',
                            size: vm.selfieBlob.size,
                        });

                        if (uploadUrl == null || uploadUrl.url == null) {
                            throw new Error('Errore durante il caricamento dell\'immagine');
                        }

                        // upload to s3 using signed url
                        const response = await axios.put(uploadUrl.url, vm.selfieBlob, {
                            headers: {
                                'Content-Type': vm.selfieBlob.type
                            }
                        });

                        if (response.status != 200) {
                            throw new Error('Errore durante il caricamento dell\'immagine');
                        }

                        loading.close();

                    } catch (error) {
                        Sentry.captureException(error);
                        loading.close();
                        return this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.canNotUploadImage'),
                            color: 'primary', duration: 5000,
                            position: 'top-center'
                        });
                    }

                    // WORKAROUND: we set this property when he set the tag, because in the next screen we show participants with tag and profile picture, but the event is on profile picture set
                    // this.partecipant.picture_updated_at = new Date().toISOString();

                }

                if (this.currentStep == 10) { // tag selection
                    if(this.partecipant.tag == null){
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.selectTag'),
                            position: 'top-center',
                            color: 'primary', duration: 5000,
                        });
                        return;
                    }

                    this.partecipant.picture_updated_at = new Date().toISOString();

                    // force participant to save NOW because it will be redirected to another page and we can't save it later
                    await this.saveParticipant(this.partecipant);
                }

                // exception if event type is 'FREE', skip table mode selection
                if (this.currentStep == 3 && this.event && this.event.type == 'FREE') {
                    this.currentStep = 7;
                    return;
                }

                // exception: if is table mode selection is AUTO, skip table preference
                if (this.currentStep == 4 && this.tableMode == 'AUTO') {
                    this.currentStep = 6;
                    return;
                }
            }

            this.currentStep++;
        },

        async saveParticipant(n){
            if(this.saveParticipantTimeout != null){
                clearTimeout(this.saveParticipantTimeout);
                this.saveParticipantTimeout = null;
            }
            
            let copy = JSON.parse(JSON.stringify(n));
            for (let key in copy) {
                if (copy[key] == null) {
                    delete copy[key];
                }
            }

            const result = await partecipantService.edit(copy);
            if (result.id == null) {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('OnBoarding.messages.canNotSaveChanges'),
                    position: 'top-center',
                    color: 'primary', duration: 5000,
                });
            }
        }
    },

    watch: {
        checkInInfo(n){
            console.log(n)
        },
        currentStep(n) {
            if (this.selectedAction == 2 && n == 3) {
                // go to view booking
                this.$router.push({
                    name: 'Booking',
                    params: {
                        eventId: this.selectedEvent
                    }
                })
            }

            if (this.selectedAction == 1 && n == 11) {
                // go to view waiting
                this.$router.push({
                    name: 'Waiting',
                    params: {
                        event: this.partecipant.event
                    }
                })
            }
        },

        checkInResult(n) {
            if (n) {
                if(n.event.type == 'FREE'){
                    // skip table mode selection
                    this.currentStep = 7; // jump directly to Gender Preference
                } else if(this.partecipant.type == 'COUPLE'){
                    // skip table mode selection
                    this.currentStep = 6; // jump directly to table number
                } else {
                    this.currentStep++;
                }

                this.partecipant.friendly_id = n.friendly_id;
                this.partecipant.id = n.id;
                this.partecipant.nickname = n.nickname;
                this.partecipant.age = n.age;
                this.partecipant.age_of_coparticipant = n.age_of_coparticipant;
                this.partecipant.city = n.city;
                this.event = n.event;
            }
        },

        partecipant: {
            deep: true,
            async handler(n, o) {
                if (n.id == null) {
                    return;
                }

                if(o == null || o.id == null){ // it was null, it's the first time we set it
                    return;
                }

                // step must be greater than checkin qrcode (3)
                if(this.currentStep < 3){
                    return;
                }

                if(this.saveParticipantTimeout != null){
                    clearTimeout(this.saveParticipantTimeout);
                    this.saveParticipantTimeout = null;
                }

                this.saveParticipantTimeout = setTimeout(() => {
                    this.saveParticipant(n);
                }, 1000);
            }
        }
    },

    async mounted() {
        const loading = this.$vs.loading();

        try {
            const result = await partecipantService.detail();

            if (result.id != null) {
                if(result.event.status == 'CLOSED' || result.event.id == null || result.event.id == ''){ // when is deleted evnet is empty
                    loading.close();
                    return;
                }

                this.partecipant = result;

                this.partecipant.__ob__.dep.notify();

                let temporaryStep = 4; // table mode selection

                if (result.preferred_participant_friendly_id != null) {
                    temporaryStep = 5; // table preference
                }

                if(result.event && result.event.type == 'FREE'){
                    temporaryStep = 7; // gender preference: skip table number
                } else if (result.type == 'COUPLE') {
                    temporaryStep = 6; // table number
                }

                

                // if table is set, skip table number
                if (result.table != null && result.table.id != null && result.table.id != '') {
                    temporaryStep = 7; // gender preference
                }

                if (result.looking_for_type != null) {
                    temporaryStep = 8; // ideal match
                }

                if (result.ideal_match != null) {
                    temporaryStep = 9; // selfie
                }

                // TODO if selfie is set, skip selfie
                if (result.picture_updated_at != null) {
                    temporaryStep = 10; // tag
                }

                if (result.tag != null && result.picture_updated_at != null) {
                    temporaryStep = 11; // waiting
                }

                // only if profile was been completed, handle event overrides
                if(temporaryStep == 11){
                    if(result.event != null && result.event.type == 'TABLES'){
                        // vento format tavoli

                        if(result.event.status == 'PREMATCH_QUIZ'){
                            // go to quiz
                            loading.close();
                            this.$router.push({
                                name: 'Quiz',
                                params: {
                                    event: result.event
                                }
                            })
                            return;
                        }

                        if(result.event.status == 'CALCULATE_MATCH'){
                            // go to chats
                            loading.close();
                            this.$router.push({
                                name: 'Waiting',
                                params: {
                                    afterQuiz: true
                                }
                            })
                            return;
                        }
                        if(result.event.status == 'IN_PROGRESS'){
                            // go to chats
                            loading.close();
                            this.$router.push({
                                name: 'Chats',
                            })
                            return;
                        }
                    } else if(result.event != null && result.event.type == 'FREE'){
                        // evento format libero
                        if(result.event.status == 'IN_PROGRESS'){
                            // handle case where user already answered quiz and case where user already swiped matches (must be stored locally)

                            // read MATCHES_ANSWERED
                            const matchesAnswered = JSON.parse(localStorage.getItem('MATCHES_ANSWERED') || '{}');
                            // check if event is same
                            if(matchesAnswered.event_id == result.event.id){
                                // go to chats
                                loading.close();
                                this.$router.push({
                                    name: 'Chats',
                                })
                                return;
                            }

                            // read QUIZ_ANSWERED 
                            const quizAnswered = JSON.parse(localStorage.getItem('QUIZ_ANSWERED') || '{}');
                            // check if event is same
                            if(quizAnswered.event_id == result.event.id){
                                // go to matches
                                loading.close();
                                this.$router.push({
                                    name: 'Matches',
                                })
                                return;
                            }

                            // go to quiz
                            loading.close();
                            this.$router.push({
                                name: 'Quiz',
                                params: {
                                    event: result.event
                                }
                            })
                            
                            return;
                        }
                    }
                }

                this.currentStep = temporaryStep;
            }
        } catch (e) {
            // it's not an error, it's just a new user

        }
        loading.close();

    }
}
</script>

<style scoped>
.on-boarding {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background: white;
}
</style>
