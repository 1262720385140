import { render, staticRenderFns } from "./SelfieSelection.vue?vue&type=template&id=78afd1c8&scoped=true"
import script from "./SelfieSelection.vue?vue&type=script&lang=js"
export * from "./SelfieSelection.vue?vue&type=script&lang=js"
import style0 from "./SelfieSelection.vue?vue&type=style&index=0&id=78afd1c8&prod&lang=css"
import style1 from "./SelfieSelection.vue?vue&type=style&index=1&id=78afd1c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78afd1c8",
  null
  
)

export default component.exports