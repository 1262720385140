<template>
<div class="action-selection__content">
    <vs-dialog v-model="showCropper">
    <div class="cropper_dialog_body">
            <div class="cropper_header">
                <vs-avatar circle badge writing badge-color="success">
                    <img :src="imgCropped" />
                </vs-avatar>

                <div class="heading">
                    <h2>{{partecipant.nickname}}</h2>
                    <p>
                        <template v-if="partecipant.age_of_coparticipant">
                            {{ partecipant.age }} & {{ partecipant.age_of_coparticipant }}
                        </template>
                        <template v-else>
                            {{ partecipant.age }}
                        </template>
                        Anni, {{ partecipant.city }}</p>
                </div>
            </div>
            <cropper class="cropper" :src="img" style="height: 45vh;" :stencil-props="{
                aspectRatio: 1/1
            }" 
            :canvas="{
                width: 1000,
                height: 1000
            }"
            :stencil-component="CircleStencil" @change="cropperChange" />
        </div>

        <template #footer>
            <div style="width: 100%; display: flex; flex-direction: column; gap: 1rem;">
                <PtyButton id="pty__SelfieSelection_confirmButton" primary block @click="next">{{ $t('SelfieSelection.confirmButton') }}</PtyButton>
                <PtyButton id="pty__SelfieSelection_cancelButton" light-blue block @click="cancel">{{ $t('SelfieSelection.cancelButton') }}</PtyButton>
            </div>
        </template>
    </vs-dialog>
    <h1>{{ $t('SelfieSelection.title') }}</h1>
    <p style="text-wrap: pre-line;">
        {{ $t('SelfieSelection.message') }}
    </p>
    <div class="selfie-container">

        <vs-avatar circle badge badge-color="primary" size="100" v-if="imgCropped">
            <img :src="imgCropped" />
            <template #badge>
                <PtyButton id="pty__bwn55ie00" size="xs" style="width: 40px; height: 40px;" no-padding @click="$refs.cameraInput.click()">
                    <PlusIcon />
                </PtyButton>
            </template>
        </vs-avatar>
        <PtyButton id="pty__xo2rzlf4p" :circle="true" @click="$refs.cameraInput.click()" v-else>
            <PlusIcon />
        </PtyButton>
        <!-- force to open camera-->
        <input type="file" accept="image/*" capture="camera" style="display: none;" ref="cameraInput" @change="onCameraInput" />
    </div>
</div>
</template>

<script>
import PlusIcon from '../../assets/images/icons/plus.svg';
import PtyButton from '../../components/PtyButton.vue';
import {
    Cropper,
    CircleStencil
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
export default {
    name: 'SelfieSelection',
    components: {
        PtyButton,
        PlusIcon,
        Cropper,
    },
    data: () => ({
        img: '',
        imgCropped: '',
        showCropper: false,
        CircleStencil
    }),

    mounted() {

    },

    props: {
        partecipant: {
            type: Object,
            required: true
        }
    },

    methods: {
        cancel: function () {
            this.showCropper = false;
            this.img = '';
            this.imgCropped = '';
        },

        next: function () {
            this.showCropper = false;
        },

        onCameraInput(e) {
            const file = e.target.files[0];
            if (file) {
                // upload file
                console.log(file);

                // store base64 into img
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.img = e.target.result;
                    this.showCropper = true;
                }
                reader.readAsDataURL(file);

            }
        },

        async cropperChange({
            canvas
        }) {
            const vm = this;
            vm.imgCropped = canvas.toDataURL();

            canvas.toBlob(async (blob) => {
                vm.$emit('input', blob);
            }, 'image/jpeg', 0.90);

        }
    },

    watch: {

    }
}
</script>
<style>
.selfie-container .vs-avatar__badge {
    background: transparent;
    border: unset !important;
    bottom: -10px;
}</style>
<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #494949;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}

.selfie-container {
    border-radius: 1.5625rem;
    border: 1px dashed #00A9C0;
    background: #F3F9FF;
    width: 13.0625rem;
    height: 16.95531rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.cropper_header {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.cropper_header .heading {
    display: flex;
    flex-direction: column;
    gap: 0 !important;
}

.cropper_header .heading h2,
.cropper_header .heading p {
    margin: 0;
}

.cropper_dialog_body {
    padding: 0rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
</style>
